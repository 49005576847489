@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slider .slick-dots{ text-align: right !important; bottom: 10px !important; right: 10px; }
.slick-slider .slick-dots li{ margin: 0 !important; }
.slick-slider .slick-dots li button:before{ font-size: 12px !important; }
.slick-slider .slick-next{ right: 0 !important; }
.slick-slider .slick-prev{ left: 0 !important; }
.slick-slider .slick-arrow{ z-index: 10; height: inherit !important; width: inherit !important; background-color: rgba(0,0,0,0.6) !important; padding: 20px 5px !important; opacity: 0; transition: all .25s ease-in-out; -moz-transition: all .25s ease-in-out; -webkit-transition: all .25s ease-in-out;}
.slick-slider:hover > .slick-arrow{ opacity: 1; }
.slick-arrow:before{ content:'' !important }

.chevron{ clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%); }

.wide-slider .slick-slider .slick-track,
.featured-slider .slick-slider .slick-track{ display: flex !important; }

.wide-slider .slick-slider .slick-slide,
.featured-slider .slick-slider .slick-slide{ height: inherit !important; display: flex !important; justify-content: center !important; align-items: center !important; }

.wide-slider .slick-slider .slick-slide > div,
.featured-slider .slick-slider .slick-slide > div { height: 100% !important; }

.wide-slider .slick-slider .slick-slide, .featured-slider .slick-slider .slick-slide { margin: 5px; }
.wide-slider .slick-list, .featured-slider .slick-list { margin-left: -5px !important; }
.featured-slider .slick-slider .slick-list{ overflow: inherit !important }
